import { Autocomplete, TextField } from '@mui/material';
import { RestApiClient } from '@shahadul-17/rest-api-client';
import { useState } from 'react';
import { Spinner } from '../../Spinner';
import { useExtendedState, useInitiator } from '../../../hooks';
import { ArrayUtilities, StringUtilities } from '../../../utilities';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Styles from "./EditUserProject.module.scss";

const prepareProductOptions = products => {
  const options = [];

  for (const product of products) {
    options.push({
      // multiple products may have same title...
      label: `${product.title} (${product.productId})`,
      value: product.productId,
    });
  }

  return options;
};

const PaymentCard = ({ index, projectId, paymentDatum, onEdit, onDelete, }) => {

  const onPaymentCardEditButtonClickedAsync = async event => {
    if (typeof onEdit !== 'function') { return; }

    await onEdit({ index, event, paymentDatum, });
  };

  const onPaymentCardDeleteButtonClickedAsync = async event => {
    if (typeof onDelete !== 'function') { return; }
    else if (!window.confirm('Are you sure you want to delete this payment history?')) { return; }

    const response = await RestApiClient.getInstance().sendSmartRequestAsync({
      routeName: 'deleteProjectPayment',
      data: {
        projectId: projectId,
        paymentId: paymentDatum.paymentId,
        paymentInformationOnly: true,
      },
    });

    let payment;
    let paymentId;

    if (response.status === 200) {
      payment = response.jsonData.data.payment;
      paymentId = payment.paymentId;
    }

    await onDelete({ index, event, response, payment, paymentId, previousPaymentData: paymentDatum, });
  };

  return <tr>
    <td> {paymentDatum?.title ?? 'N/A'}</td>
    <td> {paymentDatum?.amount ? `${paymentDatum.amount} BDT` : 'N/A'}</td>
    <td>{paymentDatum?.date ?? 'N/A'}</td>
    <td>{paymentDatum?.status === 'Done' ? paymentDatum.status : 'Pending'}</td>
    <td> <button className={Styles.btn} type='button' onClick={onPaymentCardEditButtonClickedAsync}>Edit</button></td>
    <td><button className={Styles.btn} type='button' onClick={onPaymentCardDeleteButtonClickedAsync}>Delete</button></td>
  </tr>;
};

const CreateOrUpdatePayment = ({ projectId, paymentDataIndex, paymentData, onPaymentAddOrUpdate, onCancel, }) => {
  const [_paymentData, updatePaymentData, onInputValueChanged, setPaymentData,] = useExtendedState(paymentData ?? {});

  const onFormSubmittedAsync = async event => {
    event.preventDefault();

    if (typeof onPaymentAddOrUpdate !== 'function') { return; }

    const response = await RestApiClient.getInstance().sendSmartRequestAsync({
      routeName: 'upsertProjectPayment',
      data: {
        ..._paymentData,
        status: StringUtilities.getDefaultIfUndefinedOrNullOrEmpty(_paymentData.status, 'Pending', true),
        paymentId: paymentData?.paymentId,
        projectId: projectId,
        paymentInformationOnly: true,
      },
    });

    let payment;
    let paymentId;

    if (response.status === 200) {
      payment = response.jsonData.data.payment;
      paymentId = payment.paymentId;
    }

    await onPaymentAddOrUpdate({
      mode: paymentData?.paymentId ? 'UPDATE' : 'CREATE',
      response,
      projectId,
      paymentId: paymentId,
      currentPaymentData: payment,
      previousPaymentData: paymentData,
      paymentDataIndex,
    });
  };

  useInitiator(() => {
    setPaymentData(paymentData);
  }, [paymentData]);

  return <div className={Styles.paymentContainer}>
    <form autoComplete='off' onSubmit={onFormSubmittedAsync}>
      <input type="hidden" name="projectId" value={projectId} />

      <label htmlFor="title">Payment Title</label>
      <input type="text" name="title" value={_paymentData?.title ?? ''} onChange={onInputValueChanged} />

      <label htmlFor="amount">Payment Amount (BDT)</label>
      <input type="number" name="amount" value={_paymentData?.amount ?? ''} onChange={onInputValueChanged} />

      <label htmlFor="date">Payment Date</label>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DesktopDatePicker
          label={''}
          value={_paymentData?.date ?? null}
          inputFormat="MM/dd/yyyy"
          onChange={(value, keyboardInputValue) => onInputValueChanged({ target: { name: 'date', value, keyboardInputValue, } })}
          renderInput={params => <TextField {...params} />} />
      </LocalizationProvider>

      <label htmlFor="status">Status</label>
      <select name="status" value={_paymentData?.status ?? "Pending"} onChange={onInputValueChanged}>
        <option value={"Pending"}>Pending</option>
        <option value={"Done"}>Done</option>
      </select>
      <div className={Styles.btnContainer}>
        <button className={Styles.btn} type='submit'>{paymentDataIndex === -1 ? 'Add' : 'Update'}</button>
        {paymentDataIndex !== -1 && <button className={Styles.btn} type='button' onClick={onCancel}>Cancel</button>}
      </div>
    </form>
  </div>;
};

const MessageBox = ({ projectId, messageDataIndex, messageData, onMessageAddOrUpdate, onCancel, }) => {
  const [_messageData, updateMessageData, onInputValueChanged, setMessageData,] = useExtendedState(messageData ?? {});

  const onFormSubmittedAsync = async event => {
    event.preventDefault();

    if (typeof onMessageAddOrUpdate !== 'function') { return; }

    const response = await RestApiClient.getInstance().sendSmartRequestAsync({
      routeName: 'upsertProjectMessage',
      data: {
        ..._messageData,
        status: StringUtilities.getDefaultIfUndefinedOrNullOrEmpty(_messageData.status, 'Pending', true),
        messageId: messageData?.messageId,
        projectId: projectId,
        messageInformationOnly: true,
      },
    });

    let message;
    let messageId;

    if (response.status === 200) {
      message = response.jsonData.data.message;
      messageId = message.messageId;
    }

    await onMessageAddOrUpdate({
      mode: messageDataIndex === -1 ? 'CREATE' : 'UPDATE',
      response,
      projectId,
      messageId,
      currentMessageData: message,
      previousMessageData: messageData,
      messageDataIndex,
    });
  };

  useInitiator(() => {
    setMessageData(messageData);
  }, [messageData]);

  return <div >
    <form autoComplete='off' onSubmit={onFormSubmittedAsync}>
      <input type="hidden" name="projectId" value={projectId} />

      <label htmlFor="content">Message</label>
      <textarea name="content" value={_messageData?.content ?? ''} onChange={onInputValueChanged} />

      <label htmlFor="status">Status</label>
      <select name="status" value={_messageData?.status ?? 'Pending'} onChange={onInputValueChanged}>
        <option value={'Pending'}>Pending</option>
        <option value={'Acknowledged'}>Acknowledged</option>
      </select>

      <div className={Styles.btnContainer}>
          <button className={Styles.btn} type='submit'>{messageDataIndex === -1 ? 'Send' : 'Update'}</button>
          {messageDataIndex !== -1 && <button className={Styles.btn} type='button' onClick={onCancel}>Cancel</button>}
      </div>
    </form>
  </div>;
};

const MessageCard = ({ index, projectId, messageData, onEdit, onDelete, }) => {

  const onMessageCardEditButtonClickedAsync = async event => {
    if (typeof onEdit !== 'function') { return; }

    await onEdit({ index, event, messageDatum: messageData, });
  };

  const onMessageCardDeleteButtonClickedAsync = async event => {
    if (typeof onDelete !== 'function') { return; }
    else if (!window.confirm('Are you sure you want to delete this message from history?')) { return; }

    const response = await RestApiClient.getInstance().sendSmartRequestAsync({
      routeName: 'deleteProjectMessage',
      data: {
        projectId: projectId,
        messageId: messageData.messageId,
        messageInformationOnly: true,
      },
    });

    let message;
    let messageId;

    if (response.status === 200) {
      message = response.jsonData.data.message;
      messageId = message.messageId;
    }

    await onDelete({ index, event, response, message, messageId, previousMessageData: messageData, });
  };

  return <tr>
    <td> {messageData?.content ?? 'N/A'}</td>
    <td> {messageData?.senderName ?? 'N/A'}</td>
    <td> {messageData?.senderMobile ?? 'N/A'}</td>
    <td> {messageData?.createdAt ? new Date(messageData.createdAt).toString() : 'N/A'}</td>
    <td> {messageData?.updatedAt ? new Date(messageData.updatedAt).toString() : 'N/A'}</td>
    <td> {messageData?.status ?? 'Pending'}</td>
    <td><button className={Styles.btn} type='button' onClick={onMessageCardEditButtonClickedAsync}>Edit</button></td>
    <td><button className={Styles.btn} type='button' onClick={onMessageCardDeleteButtonClickedAsync}>Delete</button></td>
  </tr>;
};

const UploadOrUpdateDocument = ({ documentDataIndex, projectId, documentData, onDocumentUploadOrUpdate, onCancel, }) => {
  let fileInput;
  // const [uploadedFileData, setUploadedFileData] = useState({});
  const [_documentData, updateDocumentData, onInputValueChanged, setDocumentData,] = useExtendedState(documentData ?? {});

  const onFileSelectionChangedAsync = async event => {
    const files = event.target.files;

    if (!files.length) { return; }

    const file = files[0];
    const formData = new FormData();
    formData.append('files', file);
    formData.append('tags', `PROJECT_DOCUMENT, PROJECT_ID = ${projectId}, DOCUMENT_ID = ${documentData?.documentId ?? 'N/A'}`);

    const response = await RestApiClient.getInstance().sendSmartRequestAsync({
      routeName: 'uploadFiles',
      data: formData,
    });

    // errors shall be shown...
    if (response.status !== 200 || !Array.isArray(response.jsonData.data?.files)) { return; }

    const uploadedFile = response.jsonData.data.files[0];

    updateDocumentData({
      size: uploadedFile.fileSize,
      type: uploadedFile.contentType,
      url: uploadedFile.url,
    });

    console.log(uploadedFile);
  };

  const onFormSubmittedAsync = async event => {
    event.preventDefault();

    if (typeof onDocumentUploadOrUpdate !== 'function') { return; }
    // error message shall be shown...
    else if (!_documentData?.url) { return; }

    const response = await RestApiClient.getInstance().sendSmartRequestAsync({
      routeName: 'upsertProjectDocument',
      data: {
        ..._documentData,
        documentId: documentData?.documentId,
        projectId: projectId,
        documentInformationOnly: true,
      },
    });

    let document;
    let documentId;

    if (response.status === 200) {
      document = response.jsonData.data.document;
      documentId = document.documentId;
    }

    await onDocumentUploadOrUpdate({
      mode: documentDataIndex === -1 ? 'CREATE' : 'UPDATE',
      response,
      projectId,
      documentId: documentId,
      currentDocumentData: document,
      previousDocumentData: documentData,
      documentDataIndex,
    });
  };

  useInitiator(() => {
    setDocumentData(documentData);
  }, [documentData]);

  return <div >
    <p>File URL: {_documentData?.url ?? 'N/A'}</p>
    <form autoComplete='off' onSubmit={onFormSubmittedAsync}>
      <input type="hidden" name="projectId" value={projectId} />

      <label htmlFor="name">Document Name</label>
      <textarea name="name" value={_documentData?.name ?? ''} onChange={onInputValueChanged} />

      <label htmlFor="category">Document Category</label>
      <select name="category" value={_documentData?.category ?? ''} onChange={onInputValueChanged}>
        <option value={''}>None</option>
        <option value={'Document Category 1'}>Document Category 1</option>
        <option value={'Document Category 2'}>Document Category 2</option>
        <option value={'Document Category 3'}>Document Category 3</option>
      </select>

      <input type="file" accept='.xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf'
        ref={_ref => fileInput = _ref} style={{ display: 'none' }}
        onChange={onFileSelectionChangedAsync} />

        <div className={Styles.btnContainer}>
          <button className={Styles.btn} type='button' onClick={() => fileInput.click()}>Upload File</button>
          <button className={Styles.btn} type='submit'>{_documentData?.documentId ? 'Update' : 'Create'}</button>
          {documentData?.documentId && <button className={Styles.btn} type='button' onClick={onCancel}>Cancel</button>}
        </div>

     </form>
  </div>;
};

const DocumentCard = ({ index, projectId, documentData, onEdit, onDelete, }) => {

  const onDocumentCardEditButtonClickedAsync = async event => {
    if (typeof onEdit !== 'function') { return; }

    await onEdit({ index, event, documentDatum: documentData, });
  };

  const onDocumentCardDeleteButtonClickedAsync = async event => {
    if (typeof onDelete !== 'function') { return; }
    else if (!window.confirm(`Are you sure you want to delete this document, '${documentData?.name}'?`)) { return; }

    const response = await RestApiClient.getInstance().sendSmartRequestAsync({
      routeName: 'deleteProjectDocument',
      data: {
        documentId: documentData.documentId,
        projectId: projectId,
        documentInformationOnly: true,
      },
    });
    
    let document;
    let documentId;

    if (response.status === 200) {
      document = response.jsonData.data.document;
      documentId = document.documentId;
    }

    await onDelete({ index, event, response, document, documentId, previousDocumentData: documentData, });
  };

  return <tr>
    <td>{documentData?.name ?? 'N/A'}</td>
    <td>{documentData?.type ?? 'N/A'}</td>
    {/* <p>Date Uploaded: {documentData?.propertyDocDate ?? 'N/A'}</p> */}
    <td>{documentData?.size ? `${documentData.size} bytes` : 'N/A'}</td>
    <td>Category: {documentData?.category ?? 'N/A'}</td>
    <td> {documentData?.url && <a href={documentData.url} download>Download</a>}</td>
    <td><button className={Styles.btn} type='button' onClick={onDocumentCardEditButtonClickedAsync}>Edit</button></td>
    <td> <button className={Styles.btn} type='button' onClick={onDocumentCardDeleteButtonClickedAsync}>Delete</button></td>
  </tr>;
};

export const EditUserProject = ({ projectId, }) => {
  const [isLoading, setLoading] = useState(true);
  const [message, setMessage] = useState('');
  const [selectedPaymentDataIndex, setSelectedPaymentDataIndex] = useState(-1);
  const [selectedMessageDataIndex, setSelectedMessageDataIndex] = useState(-1);
  const [selectedDocumentDataIndex, setSelectedDocumentDataIndex] = useState(-1);
  const [productOptions, setPropertyOptions] = useState([]);
  const [projectData, updateProjectData, onInputValueChanged, setProjectData,] = useExtendedState();

  const onDocumentUploadedOrUpdatedAsync = async ({ mode, response, projectId, documentDataIndex, currentDocumentData, initialDocumentData, }) => {
    if (response.status !== 200) { return; }

    let _documents = Array.isArray(projectData.documents) ? projectData.documents : [];

    if (mode === 'UPDATE') {
      _documents[documentDataIndex] = currentDocumentData;
      _documents = [..._documents];
    } else {
      _documents = ArrayUtilities.addElement(currentDocumentData, _documents);
    }

    updateProjectData({ documents: _documents, });
    setSelectedDocumentDataIndex(-1);
  };

  const onDocumentDeleteClickedAsync = async ({ index, document, response, }) => {
    if (response.status !== 200) { return; }

    let _documents = Array.isArray(projectData?.documents) ? projectData.documents : [];
    _documents = ArrayUtilities.remove('documentId', document.documentId, _documents);

    updateProjectData({ documents: _documents, });
  };

  const onMessageAddedOrUpdatedAsync = async ({ mode, projectId, messageDataIndex, currentMessageData, initialMessageData, response, }) => {
    if (response.status !== 200) { return; }

    let _messages = Array.isArray(projectData.messages) ? projectData.messages : [];

    if (mode === 'UPDATE') {
      _messages[messageDataIndex] = currentMessageData;
      _messages = [..._messages];
    } else {
      _messages = ArrayUtilities.addElement(currentMessageData, _messages);
    }

    updateProjectData({ messages: _messages, });
    setSelectedMessageDataIndex(-1);
  };

  const onMessageDeleteClickedAsync = async ({ index, message, response, }) => {
    if (response.status !== 200) { return; }

    let _messages = Array.isArray(projectData.messages) ? projectData.messages : [];
    _messages = ArrayUtilities.remove('messageId', message.messageId, _messages);

    updateProjectData({ messages: _messages, });
  };

  const onPaymentAddedOrUpdatedAsync = async ({ mode, projectId, paymentId, paymentDataIndex, currentPaymentData, previousPaymentData, response, }) => {
    if (response.status !== 200) { return; }

    let _payments = Array.isArray(projectData.payments) ? projectData.payments : [];

    if (mode === 'UPDATE') {
      _payments[paymentDataIndex] = currentPaymentData;
      _payments = [..._payments];
    } else {
      _payments = ArrayUtilities.addElement(currentPaymentData, _payments);
    }

    updateProjectData({ payments: _payments, });
    setSelectedPaymentDataIndex(-1);
  };

  const onPaymentDeleteClickedAsync = async ({ index, payment, response, }) => {
    if (response.status !== 200) { return; }

    let _payments = Array.isArray(projectData.payments) ? projectData.payments : [];
    _payments = ArrayUtilities.remove('paymentId', payment.paymentId, _payments);

    updateProjectData({ payments: _payments, });
  };

  const onFormSubmittedAsync = async event => {
    event.preventDefault();

    setMessage('Please wait...');

    const restApiClient = RestApiClient.getInstance();
    const response = await restApiClient.sendSmartRequestAsync({
      routeName: 'upsertProject',
      data: {
        ...projectData,
        productId: projectData.product.value,
      },
    });

    setMessage(response.jsonData.message);

    if (response.status !== 200) { return; }
  };

  useInitiator(async () => {
    const restApiClient = RestApiClient.getInstance();
    const response = await restApiClient.sendSmartRequestAsync({
      routeName: "getMiscellaneousContents",
      data: {
        ignoreCache: true,
        contents: `projects:${projectId},users:dictionary,products`,
      },
    });

    setLoading(false);

    if (response.status !== 200) { return; }

    const project = response.jsonData.data.project;
    const products = response.jsonData.data.products;
    const productOptions = prepareProductOptions(products);
    const usersDictionary = response.jsonData.data.usersDictionary;

    setPropertyOptions(productOptions);

    project.user = usersDictionary[project.userId] ?? {};
    // project.property = productsDictionary[project.productId] ?? {};

    const productFindResult = ArrayUtilities.find('value', project.productId, productOptions);

    project.product = productFindResult.index === -1 ? {} : productFindResult.item;

    setProjectData(project);
  });

  return <div className={Styles.main}>
    {isLoading && <Spinner />}
    {!isLoading && <>
      <p>{message}</p>
    
    <h1>Project info</h1>
    <div className={Styles.projectSection}>
      <p>Please select a property</p>
      <Autocomplete
        loading={productOptions.length === 0}
        value={projectData.product ?? null}
        size='small'
        multiple={false}
        options={productOptions}
        getOptionLabel={option => option.label}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        onChange={async (_, value) => await onInputValueChanged({ target: { name: 'product', value: value, } })}
        renderInput={params => <TextField {...params} label={''} />} />
      
      {projectData.product && <form autoComplete='off' onSubmit={onFormSubmittedAsync}>
        <label htmlFor="title">Title</label>
        <input type="text" name="title" value={projectData.title ?? ''} onChange={onInputValueChanged} />

        <label htmlFor="totalCost">Total Cost (BDT)</label>
        <input type="number" name="totalCost" value={projectData.totalCost ?? ''} onChange={onInputValueChanged} />

        <label htmlFor="monthlyRent">Monthly Rent (BDT)</label>
        <input type="number" name="monthlyRent" value={projectData.monthlyRent ?? ''} onChange={onInputValueChanged} />

        <label htmlFor="monthlyEquityPayment">Monthly Equity Payment (BDT)</label>
        <input type="number" name="monthlyEquityPayment" value={projectData.monthlyEquityPayment ?? ''} onChange={onInputValueChanged} />

        <label htmlFor="totalMonths">Total Months</label>
        <input type="number" name="totalMonths" value={projectData.totalMonths ?? ''} onChange={onInputValueChanged} />
        <div className={Styles.btnContainer}>
          <button className={Styles.btn} type='submit'>Update</button>
        </div>
              
      </form>}

    </div>
      
      {/* This container contains payments. */}
      <h1>Add/Modify Payments</h1>
      <div className={Styles.projectSection}>
        
        <CreateOrUpdatePayment
          projectId={projectId}
          paymentDataIndex={selectedPaymentDataIndex}
          paymentData={selectedPaymentDataIndex === -1
            ? undefined : projectData?.payments[selectedPaymentDataIndex]}
          onPaymentAddOrUpdate={onPaymentAddedOrUpdatedAsync}
          onCancel={() => setSelectedPaymentDataIndex(-1)} />
        {projectData.payments?.length === 0 && <p>No payment record found.</p>}
        <table>
          <tbody>
            <tr>
              <th>Title</th>
              <th>Amount</th>
              <th>Date</th>
              <th>Status</th>
              <th>Edit</th>
              <th>Delete</th>
            </tr>
          {projectData.payments?.map((payment, index) => {
            return <PaymentCard key={payment.paymentId}
              index={index}
              projectId={projectId}
              paymentDatum={payment}
              onEdit={({ index, }) => setSelectedPaymentDataIndex(index)}
              onDelete={onPaymentDeleteClickedAsync} />;
          })}
          </tbody>
        </table>
      </div>

      {/* This container contains property documents. */}
      <h1>Add/Modify Documents</h1>
      <div className={Styles.projectSection}>
        <UploadOrUpdateDocument
          projectId={projectId}
          documentDataIndex={selectedDocumentDataIndex}
          documentData={selectedDocumentDataIndex === -1
            ? undefined : projectData?.documents[selectedDocumentDataIndex]}
          onDocumentUploadOrUpdate={onDocumentUploadedOrUpdatedAsync}
          onCancel={() => setSelectedDocumentDataIndex(-1)} />
        {projectData.documents?.length === 0 && <p>No document found.</p>}
        <table>
          <tbody>
            <tr>
              <th>Name</th>
              <th>Type</th>
              <th>Size</th>
              <th>Category</th>
              <th>Download</th>
              <th>Edit</th>
              <th>Delete</th>
            </tr>
          {projectData.documents?.map((document, index) => {
            return <DocumentCard key={document.documentId}
              index={index}
              projectId={projectId}
              documentData={document}
              onEdit={({ index, }) => setSelectedDocumentDataIndex(index)}
              onDelete={onDocumentDeleteClickedAsync} />;
          })}
          </tbody>
        </table>
      </div>

      {/* This container contains messages. */}
      <h1>Add/Modify Messages</h1>
      <div className={Styles.projectSection}>
        <MessageBox
          projectId={projectId}
          messageDataIndex={selectedMessageDataIndex}
          messageData={selectedMessageDataIndex === -1
            ? undefined : projectData?.messages[selectedMessageDataIndex]}
          onMessageAddOrUpdate={onMessageAddedOrUpdatedAsync}
          onCancel={() => setSelectedMessageDataIndex(-1)} />
        {projectData.messages?.length === 0 && <p>No message found.</p>}
        <table>
          <tbody>
            <tr>
              <th>Message</th>
              <th>Sender</th>
              <th>Sender Mobile</th>
              <th>Created At</th>
              <th>Updated At</th>
              <th>Status</th>
              <th>Edit</th>
              <th>Delete</th>
            </tr>
          {projectData.messages?.map((message, index) => {
            return <MessageCard key={message.messageId}
              index={index}
              projectId={projectId}
              messageData={message}
              onEdit={({ index, }) => setSelectedMessageDataIndex(index)}
              onDelete={onMessageDeleteClickedAsync} />;
          })}
          </tbody>
        </table>
      </div>
    </>}
  </div>;
};
