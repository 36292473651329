import { Autocomplete, TextField } from '@mui/material';
import { RestApiClient } from '@shahadul-17/rest-api-client';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { useExtendedState, useInitiator } from '../../../hooks';

const prepareProductOptions = products => {
  const options = [];

  for (const product of products) {
    options.push({
      // multiple products may have same title...
      label: `${product.title} (${product.productId})`,
      value: product.productId,
    });
  }

  return options;
};

export const AddUserProject = ({ userId, }) => {
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const [propertyOptions, setPropertyOptions] = useState([]);
  const [projectData, updateProjectData, onInputValueChanged,] = useExtendedState();

  const onFormSubmittedAsync = async event => {
    event.preventDefault();

    setMessage('Please wait...');

    const restApiClient = RestApiClient.getInstance();
    const response = await restApiClient.sendSmartRequestAsync({
      routeName: 'upsertProject',
      data: {
        ...projectData,
        userId: userId,
        productId: projectData.product.value,
      },
    });

    setMessage(response.jsonData.message);

    if (response.status === 200) {
      navigate(`/users/projects/edit/${response.jsonData.data.project.projectId}`);
    }
  };

  useInitiator(async () => {
    const restApiClient = RestApiClient.getInstance();
    const response = await restApiClient.sendSmartRequestAsync({
      routeName: 'getProducts',
    });

    if (response.status === 200) {
      setPropertyOptions(prepareProductOptions(response.jsonData.data.products));
    }
  });

  return <>
    <p>{message}</p>
    <p>Please select a property</p>
    <Autocomplete
      loading={propertyOptions.length === 0}
      value={projectData.product ?? null}
      size='small'
      multiple={false}
      options={propertyOptions}
      getOptionLabel={option => option.label}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      onChange={async (_, value) => await onInputValueChanged({ target: { name: 'product', value: value, } })}
      renderInput={params => <TextField {...params} label={''} />} />

    {projectData.product && <form autoComplete='off' onSubmit={onFormSubmittedAsync}>
      <label htmlFor="title">Title</label><br />
      <input type="text" name="title" value={projectData.title ?? ''} onChange={onInputValueChanged} /><br />

      <label htmlFor="totalCost">Total Cost (BDT)</label><br />
      <input type="number" name="totalCost" value={projectData.totalCost ?? ''} onChange={onInputValueChanged} /><br />

      <label htmlFor="monthlyRent">Monthly Rent (BDT)</label><br />
      <input type="number" name="monthlyRent" value={projectData.monthlyRent ?? ''} onChange={onInputValueChanged} /><br />

      <label htmlFor="monthlyEquityPayment">Monthly Equity Payment (BDT)</label><br />
      <input type="number" name="monthlyEquityPayment" value={projectData.monthlyEquityPayment ?? ''} onChange={onInputValueChanged} /><br />

      <label htmlFor="totalMonths">Total Months</label><br />
      <input type="number" name="totalMonths" value={projectData.totalMonths ?? ''} onChange={onInputValueChanged} /><br />

      <button type='submit'>Add</button>
    </form>}
  </>;
};
