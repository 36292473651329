import React from "react";
import { RestApiClient } from '@shahadul-17/rest-api-client';
import { useState } from "react";
import { useInitiator } from '../../../hooks';
import { Link, useNavigate } from 'react-router-dom';
import { ArrayUtilities } from '../../../utilities';

import Styles from "./AllUserProjects.module.scss";

const Project = ({ index, project, onEdit, onDelete, }) => {

  const onEditButtonClickedAsync = async event => {
    if (typeof onEdit !== 'function') { return; }

    await onEdit({ index, project, projectId: project.projectId, event, });
  };

  const onDeleteButtonClickedAsync = async event => {
    if (typeof onDelete !== 'function') { return; }
    if (!window.confirm(`Are you sure you want to delete ${project.user.name ?? project.userId ?? 'user'}'s project, '${project.title ?? project.projectId}'?`)) { return; }

    await onDelete({ index, project, projectId: project.projectId, event, });
  };

  return <tr>
    <td>{index + 1}</td>
    <td>{project.title ?? 'N/A'}</td>
    <td>{project.user.userId ? <Link to={`/users/edit/${project.user.userId}`}>{project.user.name ?? project.userId ?? 'N/A'}</Link> : 'N/A'}</td>
    <td>{project.product.productId ? <Link to={`/properties/edit/${project.productId ?? ''}`}>{project.product.title ?? project.productId ?? 'N/A'}</Link> : 'N/A'}</td>
    <td>{project.totalCost ?? 'N/A'}</td>
    <td>{project.totalPaidOff ?? 'N/A'}</td>
    <td>{project.totalOutStanding ?? 'N/A'}</td>
    <td>{project.monthlyRent ?? 'N/A'}</td>
    <td>{project.monthlyEquityPayment ?? 'N/A'}</td>
    <td>{project.totalMonths ?? 'N/A'}</td>
    <td>
      <button type='button' onClick={onEditButtonClickedAsync}>Edit</button>
    </td>
    <td>
      <button type='button' onClick={onDeleteButtonClickedAsync}>Delete</button>
    </td>
  </tr>;
};

export const AllUserProjects = () => {
  const navigate = useNavigate();
  const [projects, setProjects] = useState([]);

  const onProjectEditClickedAsync = async ({ index, projectData, projectId, event, }) => {
    navigate(`/users/projects/edit/${projectId}`);
  };

  const onProjectDeleteClickedAsync = async ({ index, projectData, projectId, event, }) => {
    const response = await RestApiClient.getInstance().sendSmartRequestAsync({
      routeName: 'deleteProject',
      data: {
        projectId: projectId,
      },
    });

    if (response.status !== 200) { return; }

    const _projects = ArrayUtilities.remove('projectId', projectId, projects);

    setProjects(_projects);
  };

  useInitiator(async () => {
    const response = await RestApiClient.getInstance().sendSmartRequestAsync({
      routeName: 'getMiscellaneousContents',
      data: {
        ignoreCache: true,
        contents: `projects,users:dictionary,products:dictionary`,
      },
    });

    if (response.status !== 200) { return; }

    // users are retrieved as key value pair...
    const users = response.jsonData.data.usersDictionary ?? {};
    // products are retrieved as key value pair...
    const products = response.jsonData.data.productsDictionary ?? {};
    const projects = response.jsonData.data.projects ?? [];

    for (let i = 0; i < projects.length; i++) {
      const project = projects[i];
      project.user = users[project.userId] ?? {};
      project.product = products[project.productId] ?? {};
    }

    setProjects(projects);
  });

  return <div className={Styles.main}>
    <h1>All Projects</h1>
    <table>
      <tbody>
        <tr>
          <th>Id</th>
          <th>Project Title</th>
          <th>User</th>
          <th>Property</th>
          <th>Total Cost (BDT)</th>
          <th>Total Paid Off (BDT)</th>
          <th>Total Outstanding (BDT)</th>
          <th>Monthly Rent (BDT)</th>
          <th>Monthly Equity Payment (BDT)</th>
          <th>Total Months</th>
          <th></th>
          <th></th>
        </tr>
        {projects?.map((project, index) => {
          return <Project
            key={project.projectId}
            index={index}
            project={project}
            onEdit={onProjectEditClickedAsync}
            onDelete={onProjectDeleteClickedAsync} />;
        })}
      </tbody>
    </table>
  </div>;
};
